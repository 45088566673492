.app__works {
    flex: 1 ;
    width: 100%;
    flex-direction: column;
}

.app__work-filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 4rem 0 2rem;
}

.app__work-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;

    &:hover {
        background-color: var(--secondary-color);
        color: #fff;
    }

    @media screen and (min-width: 2000px) {
        padding: 1rem 2rem;
        border-radius: 0.85;
    }
}

.item-active {
    background-color: var(--secondary-color);
    color: #fff;
}

.app__work-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .app__work-item {
        width: 300px;
        flex-direction: column;
        margin: 2rem;
        padding: 1.25rem;
        border-radius: 0.5rem;
        background-color: rgb(223, 223, 223);
        color: #000;
        transition: all 0.3s ease;

        &:hover {
            box-shadow: 0 0 25px var(--lightGray-color);
            background-color: var(--secondary-color);
        }

        @media screen and (min-width: 2000px) {
            width: 400px;
            padding: 1.75rem;
            border-radius: 0.75rem;
        }
        
        @media screen and (max-width: 300px) {
            width: 100%;
            margin: 1rem;
        }
    }
}

.app__work-title {
    width: 100%;
    margin-top: 0.5rem;
    position: relative;
    flex-direction: column;
    background-color: inherit;
}

.app__work-img {
    width: 100%;
    height: 180px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        object-fit: cover;
    }

    @media screen and (min-width: 2000px) {
        height: 240px;

    }
}

.app__work-hover {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border-radius: 0.5rem;
    opacity: 0;
    
    transition: all 0.3s ease;
    background-color: (0,0,0,0.5);

    div {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
    
        margin: 0.75rem;
        font-family: var(--font-base);
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;
    
         svg {
          width: 50%;
          height: 50%;
          color: var(--white-color);
        }
    }

    &:hover {
        opacity: 1;
    }
    
    @media screen and (max-width: 900px) {
        opacity: 1;
    }
}
    
.app__work-content {
    width: 100%;
    position: relative;
    flex-direction: column;

    h4 {
      margin-top: 0rem;
      line-height: 1.5;

      @media screen and (min-width: 2000px) {
        margin-top: 3rem;
      }
    }
}
