.app__skills {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
  .app__skills-container {
    width: 80%;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
  
    @media screen and (max-width: 900px) {
      width: 100%;
      flex-direction: column;
    }
  }
  
  .app__skills-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 5rem;
  
    @media screen and (max-width: 900px) {
      margin-right: 0;
      justify-content: center;
      align-items: center;
    }
  }
  
  .app__skills-item {
    flex-direction: column;
    text-align: center;
    margin: 1rem;
  
    a {
      width: 100px;
      height: 100px;
      background-color: transparent;
      cursor: default;
  
      img {
        width: 50%;
        height: 50%;
      }
  
      @media screen and (min-width: 2000px) {
        width: 150px;
        height: 150px;
      }
  
      @media screen and (max-width: 450px) {
        width: 70px;
        height: 70px;
      }
    }
  
    p {
      font-weight: 500;
      margin-top: 0.5rem;
    }
  
    @media screen and (min-width: 2000px) {
      margin: 1rem 2rem;
  
      p {
        margin-top: 1rem;
      }
    }
  }

.app__skills-exp {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    margin-top: 2rem;
  }
}

.app__skills-exp-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 2rem;

}

.app__skills-exp-works {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  

  .app__skills-exp-work {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 2rem;

    @media screen and (max-width: 900px) {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    h4 {
      font-weight: 500;
    }

    p {
      font-weight: 400;
      color: var(--gray-color);
      margin-top: 5px;
    }
  }

  
}

.app__skills-exp-year {
  margin-right: 3rem;

  p {
    font-weight: 800;
    color: var(--secondary-color);
  }

  @media screen and (max-width: 450px) {
    margin-right: 1rem;
  }
}
