.app__footer {
    flex: 1;
    width: 100%;
    flex-direction: column;
  
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  
  .app__footer-cards {
    width: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin: 4em 2rem 2rem;
  
    .app__footer-card {
      min-width: 290px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
  
      margin: 1rem 0;
      padding: 1rem;
      border-radius: 10px;
      cursor: pointer;
      background-color: #fef4f5;
  
      transition: all 0.3s ease-in-out;
  
      svg {
        margin: 0 0.7rem;
      }
  
      p {
        font-weight: 500;
      }
      a {
        text-decoration: none;
        font-weight: 500;
      }
      &:hover {
        box-shadow: 0 0 15px #fef4f5;
      }
  
      @media screen and (max-width: 450px) {
        width: 100%;
      }
    }
  
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  
  .app__footer-cards .app__footer-card:last-child {
    background-color: #f2f7fb;
  }
  
  .app__footer-cards .app__footer-card:last-child:hover {
    box-shadow: 0 0 15px #f2f7fb;
  }
  
  .app__footer-form {
    width: 100%;
    flex-direction: column;

    form {
      width: 60%;
      flex-direction: column;
      margin: 1rem 2rem;
      padding: 2rem;
      background: #60892f;
      border-radius: 10px;

      div {
        width: 100%;
        margin: 0.75rem 0;
        border-radius: 10px;
        cursor: pointer;
        background-color: var(--primary-color);
    
        transition: all 0.3s ease-in-out;
    
        input,
        textarea {
          width: 100%;
          padding: 0.95rem;
          border: none;
          border-radius: 7px;
          background-color: var(--white-color);
          outline: none;
        }
    
        textarea {
          height: 170px;
        }
    
        &:hover {
          box-shadow: 0 0 25px var(--primary-color);
        }
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        margin: 1rem 0;
      }
    }
    button {
      padding: 1rem 2rem;
      border-radius: 10px;
      border: none;
      background-color: var(--secondary-color);
  
      font-weight: 500;
      color: var(--white-color);
      outline: none;
      margin: 1rem 0 3rem 0;
      font-family: var(--font-base);
  
      transition: all 0.3s ease-in-out;
      cursor: pointer;
  
      &:hover {
        box-shadow: 0 0 15px #f2f7fb;
      }
    }
  
   
  }

  .app__form-submitted {
    flex-direction: column;
    width: auto;
    margin: 1rem;
    padding: 2rem;
    background: #60892f;
    border-radius: 10px;

    button {
      padding: 1rem 2rem;
      border-radius: 10px;
      border: none;
      background-color: #fef4f5;
  
      font-weight: 500;
      color: var(--gray-color);
      outline: none;
      margin: 1rem;
      font-family: var(--font-base);
  
      transition: all 0.3s ease-in-out;
      cursor: pointer;
  
      &:hover {
        box-shadow: 0 0 15px #f2f7fb;
      }
    }
  }
  

  .app__social-items {
    margin: 1rem;
    flex-direction: row;

    div {
      width: 50px;
      height: 50px;
      background-color: transparent;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      
        svg {
        width: 30px;
        height: 30px;
        color: var(--lightGray-color);
      }
  
      &:hover {
          svg {
            color: var(--white-color);
          }
      }
  
      @media screen and (min-width: 2000px) {
        width: 60px;
        height: 60px;
  
        svg {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

  .app__contatact-validation {
    text-align: center;
    border-radius: 5px;
    padding: 0.5rem 1rem;

    &:hover {
        cursor: default;
        box-shadow: none;
    }
  }

  .app__footer-container {
    display: flex;
    flex-direction: column;
  }