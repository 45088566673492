@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;
  /* ---------colors--------- */
  --primary-color: #222629;
  --secondary-color: #86C232;
  --black-color: #474B4F;  /*black-color*/
  --lightGray-color: #6B6E70;  /*lightgray-color*/
  --text1-color: #FEFFFF; /*gray-color*/
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth; 
}