#home {
  position: relative;

  .app__wrapper {
    padding: 0;
  }
}

.app__header {
  width: 100%;
  height: 100%; 
  background: transparent;
}

.app__header-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .app__title,
  .sub-tag {
    width: 100%;
  }

  .sub-tag {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(10px);

    p {
      background-color: var(--lightGray-color);
      border-radius: 10%/50%;
      padding: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      width: 100%;

      @media screen and (min-width: 2000px) {
        padding: 1rem;
      }
    }
  }
}

.app__projects-btn {
  position: absolute;
  right: 3rem;
  bottom: 3rem;
  background-color: var(--secondary-color);
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  @media screen and (max-width: 450px) {
    right: 1.5rem;
    bottom: 2rem;
  }

  .projects-btn {
    a {
      text-decoration: none;
      padding: 0.5rem;
    }
    @media screen and (min-width: 2000px) {
     
    }

  }
  &:hover {
    box-shadow: 0 0 25px var(--lightGray-color);
  }
}

.down-arrow-container {
  position: absolute;
  bottom: 6.5rem;
  width: 100%;
  
    button {
      background: transparent;
      border: none;

        svg{
          color: var(--white-color);
          height: 50px;
          width: 50px;
          cursor: pointer;

        @media screen and (min-width: 2000px) {
          height: 70px;
          width: 70px;
        }
      }
    }

    @media screen and (max-width: 500px) {
      display: none;
    }  
}